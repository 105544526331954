import { useEffect } from 'react';
import Checkbox from '@/Components/Checkbox';
import GuestLayout from '@/Layouts/System/GuestLayout';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import { Head, Link, useForm } from '@inertiajs/react';

export default function Login({ status, canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();

        post(route('login'));
    };

    return (
        <GuestLayout>
            <Head title="Logowanie" />

            {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

            <div className="welcome-message text-center font-bold mb-11">
                <p className="md:text-40 text-28 text-white lg:text-black">
                    Witamy spowrotem!
                </p>
                <p className="md:text-20 text-18 text-white lg:text-black">
                    Miło cię znowu widzieć
                </p>
            </div>

            <form onSubmit={submit}>
                <div>
                    <InputLabel htmlFor="email" value="Email" className='text-white lg:text-black' />

                    <TextInput
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Wprowadź adres e-mail"
                        value={data.email}
                        className="mt-1 block w-full placeholder-gray-50"
                        autoComplete="username"
                        isFocused={true}
                        onChange={(e) => setData('email', e.target.value)}
                    />

                    <InputError message={errors.email} className="mt-2" />
                </div>

                <div className="mt-[30px]">
                    <InputLabel htmlFor="password" value="Hasło" className='text-white lg:text-black' />

                    <TextInput
                        id="password"
                        type="password"
                        name="password"
                        placeholder="******"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        onChange={(e) => setData('password', e.target.value)}
                    />

                    <InputError message={errors.password} className="mt-2" />
                </div>

                <div className="block mt-[30px]">
                    <label className="flex items-center">
                        <Checkbox
                            name="remember"
                            checked={data.remember}
                            onChange={(e) => setData('remember', e.target.checked)}
                        />
                        <span className="ml-2 text-20 text-white lg:text-black">Zapamiętaj mnie</span>
                    </label>
                </div>

                <div className="flex flex-wrap-reverse gap-y-3 sm:gap-x-4 gap-x-2 items-center sm:justify-end justify-center mt-[72px] lg:mt-[30px]">
                    {canResetPassword && (
                        <Link
                            href={route('password.request')}
                            className="underline md:text-20 text-16 font-medium text-white lg:text-black hover:text-yellow transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Zapomniałeś hasła?
                        </Link>
                    )}

                    <PrimaryButton disabled={processing}>
                        Zaloguj
                    </PrimaryButton>
                </div>
            </form>
        </GuestLayout>
    );
}
